<template>
  <div>
    <title>SIM INVENTORY ~ PRODUCT DATA UPDATE</title>
    <section class="content-header">
      <h1>
        Product Data Update
        <br />
        <h4>Please edit product transaction data</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Product Transaction</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
            <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Product Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Product Number</label>
                  <input
                    type="text"
                    v-model="produkno"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    v-model="idxprodukno"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Product Name</label>
                  <input
                    type="text"
                    v-model="produknm"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Size</label>
                  <input
                    type="text"
                    v-model="ukuran"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Size"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Color</label>
                  <input
                    type="text"
                    v-model="warna"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Color"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">SKU</label>
                  <input
                    type="text"
                    v-model="skuf"
                    autocomplete="off"
                    class="form-control"
                    placeholder="SKU"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Copyright</label>
                  <input
                    type="text"
                    v-model="haki"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Copyright"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="catatan"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/produk">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "updtdt",
  data() {
    return {
      loading: false,
      produkno: "",
      idxprodukno: "",
      produknm: "",
      ukuran: "",
      warna: "",
      skuf: "",
      haki: "",
      catatan: ""
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/produk_h/getproduk_hbyid?id="+this.$route.params.id;
      const urlAPIget =
        this.$apiurl + "produk_h/getproduk_hbyid?id=" + this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.idxprodukno = resp.data.data.id;
          this.produkno = resp.data.data.nomor_produk;
          this.produknm = resp.data.data.nama_produk;
          this.haki = resp.data.data.copyright;
          this.ukuran = resp.data.data.size;
          this.warna = resp.data.data.color;
          this.skuf = resp.data.data.sku;
          this.catatan = resp.data.data.notes;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateData() {
      this.loading = true;
      // alert(this.$route.params.id)
      var nop = this.produkno;
      var namap = this.produknm;
      var ukuranp = this.ukuran;
      var warnap = this.warna;
      var skufp = this.skuf;
      var hakip = this.haki;
      var catatanp = this.catatan;
      var idx = this.idxprodukno;
      if (nop == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Product Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (namap == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Product Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (ukuranp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Size can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (warnap == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Color can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (skufp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "SKU can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (hakip == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Copyright can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catatanp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          nomor_produk: this.produkno,
          nama_produk: this.produknm,
          size: this.ukuran,
          color: this.warna,
          sku: this.skuf,
          copyright: this.haki,
          notes: this.catatan,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIUpdate = "http://26.183.23.191/inventory/backend/inventory/api/produk_h/updateproduk_h/"+ idx;
        const urlAPIUpdate = this.$apiurl + "produk_h/updateproduk_h/" + idx;

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIUpdate, paramdata, { headers: headers })
          .then((resp) => {
            console.log(resp);
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "produkheader" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "produkheader" });
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            this.loading = false;
          });
      }
    }
  }
};
</script>
